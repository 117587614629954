import * as React from "react"
import { graphql, Link } from "gatsby"
import { Box, Flex, Grid, Image, Text } from "theme-ui"
import placeholderProfilePic1 from "../images/img6.svg"
import placeholderProfilePic2 from "../images/img7.svg"
import placeholderProfilePic3 from "../images/img9.svg"
import placeholderProfilePic4 from "../images/img12.svg"
import placeholderProfilePic5 from "../images/img13.svg"
import {
  PaddedContent,
  PageHeading,
  PageWrapper,
  SectionTitle,
} from "../components/modern/layout"
import { SimpleButton } from "../components/modern/buttons"
import { StyledHtmlContent } from "../components/styled-html-content"

export const pageQuery = graphql`
  query GroupBySlug($slug: String!, $members: [String], $tags: [String]) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        resources {
          name
          link
        }
        logo {
          publicURL
        }
        members {
          slug
          role
        }
        externalArticlesTitle
        externalArticles {
          date(formatString: "D MMMM YYYY")
          url
          title
        }
      }
      members {
        slug
        name
      }
    }
    profileImages: allFile(
      filter: {
        sourceInstanceName: { eq: "profilePictures" }
        name: { in: $members }
      }
    ) {
      edges {
        node {
          name
          children {
            ... on ImageSharp {
              id
              fixed(
                height: 300
                width: 200
                cropFocus: NORTH
                toFormat: WEBP
                webpQuality: 85
              ) {
                src
              }
            }
          }
        }
      }
    }
    articles: allMarkdownRemark(
      filter: { frontmatter: { key: { ne: "group" }, tags: { in: $tags } } }
      sort: { fields: fields___date, order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            date(formatString: "D MMMM YYYY")
            slug
          }
          frontmatter {
            title
            shortTitle
            description
            tags
          }
        }
      }
    }
  }
`

type Group = {
  html: string
  fields: {
    slug: string
  }
  frontmatter: {
    title: string
    resources?: Resource[]
    logo: {
      publicURL: string
    }
    members: Array<{
      slug: string
      role?: string
    }>
    externalArticlesTitle?: string
    externalArticles?: ExternalArticle[]
  }
  members: Member[]
}

type Resource = {
  name: string
  link: string
}

type Member = {
  name: string
  slug: string
}

type ArticleEdge = {
  node: Article
}

type Article = {
  excerpt: string
  fields: {
    date: string
    slug: string
  }
  frontmatter: {
    title: string
    shortTitle: string
    description: string
    tags: string[]
  }
}

type ExternalArticle = {
  date: string
  url: string
  title: string
}

type FullMember = {
  picture?: string
  role?: string
} & Member

const Description: React.FC<{ group: Group }> = ({ group }) => {
  return (
    <Grid gap={`35px`}>
      <SectionTitle title="Group profile" />
      <Box>
        <StyledHtmlContent
          dangerouslySetInnerHTML={{
            __html: group.html,
          }}
        />
      </Box>
    </Grid>
  )
}

const Resources: React.FC<{ resources: Resource[] }> = ({ resources }) => (
  <Box>
    <SectionTitle title="Resources" />
    <Flex
      sx={{
        marginTop: `1.5em`,
        flexDirection: `row`,
        gap: `2em`,
      }}
    >
      {resources.map((resource, index) => (
        <SimpleButton
          label={resource.name}
          icomoonIcon=""
          url={resource.link}
          key={index}
          theme={{
            backgroundColor: `white`,
            textColor: `black`,
          }}
        />
      ))}
    </Flex>
  </Box>
)

const BlogEntryLink: React.FC<{
  children: React.ReactNode
  slug?: string
  url?: string
}> = ({ children, url, slug }) => {
  if (slug) {
    return <Link to={slug}>{children}</Link>
  }
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

type BlogEntryProps =
  | { slug: string; url?: never; title: string; date: string }
  | { slug?: never; url: string; title: string; date: string }

const BlogEntry: React.FC<BlogEntryProps> = ({ slug, url, title, date }) => (
  <Box>
    <BlogEntryLink url={url} slug={slug}>
      <Flex
        sx={{
          gap: `1.5em`,
          alignItems: `center`,
          color: `black`,
        }}
      >
        <Box
          sx={{
            fontSize: [`.9em`],
            width: `9em`,
            flexShrink: 0,
            textAlign: `right`,
          }}
        >
          <Text>{date}</Text>
        </Box>
        <Box
          sx={{
            fontSize: `1.1em`,
          }}
        >
          <Text>{title}</Text>
        </Box>
      </Flex>
    </BlogEntryLink>
  </Box>
)

const ExternalArticles: React.FC<{
  articles: ExternalArticle[]
  title?: string
}> = ({ articles, title = `External Articles` }) => {
  return (
    <Box>
      <SectionTitle title={title} />
      <Flex
        sx={{
          marginTop: `1.5em`,
          flexDirection: `column`,
          gap: `.8em`,
        }}
      >
        {articles.map(({ date, url, title }, i) => (
          <BlogEntry key={i} date={date} url={url} title={title} />
        ))}
      </Flex>
    </Box>
  )
}

const RelatedArticles: React.FC<{ edges: ArticleEdge[] }> = ({ edges }) => {
  if (edges.length === 0) {
    return null
  }

  return (
    <Box>
      <SectionTitle title="Articles from this team on our blog" />
      <Flex
        sx={{
          marginTop: `1.5em`,
          flexDirection: `column`,
          gap: `.8em`,
        }}
      >
        {edges.map(({ node }, i) => (
          <BlogEntry
            key={i}
            date={node.fields.date}
            slug={node.fields.slug}
            title={node.frontmatter.shortTitle || node.frontmatter.title}
          />
        ))}
      </Flex>
    </Box>
  )
}

const MemberList: React.FC<{ members: FullMember[] }> = ({ members }) => (
  <Grid columns={1}>
    <SectionTitle title="Members" />
    <Flex
      sx={{
        columnGap: `2.4em`,
        flexWrap: `wrap`,
        mt: [`20px`],
      }}
    >
      {members.map((member, index) => (
        <MemberCard key={index} member={member} />
      ))}
    </Flex>
  </Grid>
)

const MemberCard: React.FC<{ member: FullMember }> = ({ member }) => {
  const placeholderProfilePics = [
    placeholderProfilePic1,
    placeholderProfilePic2,
    placeholderProfilePic3,
    placeholderProfilePic4,
    placeholderProfilePic5,
  ]

  return (
    <Flex
      sx={{
        flexDirection: `column`,
        gap: `0.5em`,
        width: `6em`,
      }}
    >
      <Box
        sx={{
          background: member.role ? `blue` : `none`,
          border: member.role ? `blue .2em solid` : `none`,
          borderColor: `blue`,
          borderRadius: `.8em`,
        }}
      >
        <Box
          sx={{
            width: `6em`,
            height: `1.2em`,
            marginY: `.1em`,
            textAlign: `center`,
            color: `white`,
          }}
        >
          <Text sx={{ textTransform: `capitalize` }}>
            {member.role ? member.role : null}
          </Text>
        </Box>
        <Image
          sx={{
            width: `6em`,
            height: `9em`,
            borderRadius: `.8em`,
            objectFit: `cover`,
          }}
          src={
            member.picture ||
            // Pick a placeholder profile pic at random when the member has no picture
            placeholderProfilePics[
              Math.floor(Math.random() * placeholderProfilePics.length)
            ]
          }
        />
      </Box>
      <Box>
        <Text
          sx={{
            textTransform: `uppercase`,
            overflowWrap: `break-word`,
          }}
        >
          {member.name}
        </Text>
      </Box>
    </Flex>
  )
}

type Props = {
  data: {
    markdownRemark: Group
    profileImages: {
      edges: Array<{
        node: {
          name: string
          publicURL: string
          children: Array<{
            fixed: {
              src: string
            }
          }>
        }
      }>
    }
    articles: {
      edges: ArticleEdge[]
    }
  }
}

const GroupTemplate: React.FC<Props> = ({ data }) => {
  const group = data.markdownRemark

  const membersWithPicture: FullMember[] = group.members.map(member => ({
    ...member,
    picture: data.profileImages.edges.find(
      picture => picture.node.name === member.slug
    )?.node.children[0].fixed?.src,
    role:
      group.frontmatter.members.find(m => m.slug === member.slug)?.role || ``,
  }))

  return (
    <PageWrapper title={group.frontmatter.title}>
      <PageHeading
        title={group.frontmatter.title}
        imageSrc={
          group.frontmatter.logo.publicURL
            ? group.frontmatter.logo.publicURL
            : undefined
        }
      />

      <PaddedContent>
        <Flex
          sx={{
            flexDirection: `row`,
            flexWrap: `wrap`,
            justifyContent: `center`,
            rowGap: `2rem`,
            columnGap: `8rem`,
          }}
        >
          <Box
            sx={{
              maxWidth: `60rem`,
              flexBasis: `40rem`,
              flexGrow: 1,
            }}
          >
            <Description group={group} />
          </Box>
          <Flex
            sx={{
              maxWidth: `40rem`,
              flexBasis: `25rem`,
              flexGrow: 1,
              flexDirection: `column`,
              gap: `2rem`,
            }}
          >
            <MemberList members={membersWithPicture} />
            {group.frontmatter?.resources && (
              <Resources resources={group.frontmatter.resources} />
            )}
            <RelatedArticles edges={data.articles.edges} />
            {group.frontmatter?.externalArticles && (
              <ExternalArticles
                articles={group.frontmatter.externalArticles}
                title={group.frontmatter?.externalArticlesTitle}
              />
            )}
          </Flex>
        </Flex>
      </PaddedContent>
    </PageWrapper>
  )
}

export default GroupTemplate
